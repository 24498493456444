import { yFirstLine, ySecondLine } from './individual/configuration-plot';

interface ICoordinates {
  x: {
    from: number;
    to: number;
  };
  y: {
    from: number;
    to: number;
  };
}

export class CanvasUtils {
  static drawLine = (ctx: any, { x, y }: ICoordinates) => {
    // Set line stroke and line width
    ctx.strokeStyle = 'grey';
    ctx.lineWidth = 2;

    ctx.beginPath();
    ctx.moveTo(x.from, y.from);
    ctx.lineTo(x.to, y.to);
    ctx.stroke();
  };

  static drawOutcome = (ctx: any, solution: 'A' | 'B', score: number) => {
    const radius = 12;
    ctx.beginPath();
    ctx.fillStyle = score > 0 ? '#1dAa1d' : '#ea5d5d';

    ctx.arc(
      250 + score * 50,
      solution === 'A' ? yFirstLine : ySecondLine,
      radius,
      0,
      2 * Math.PI,
      false
    );
    ctx.fill();
  };

  static drawTimeOverSymbol = (ctx: any) => {
    // Draw time over symbol
    ctx.beginPath();
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 4;

    ctx.moveTo(240, 90);
    ctx.lineTo(260, 110);
    ctx.moveTo(260, 90);
    ctx.lineTo(240, 110);

    ctx.stroke();
  };
}
