// translation in class matrix debrief system
const xTranslation = 2;
const yTranslation = 2;

// rotation angle
const rotationAngle = Math.PI / 4;

// scale factor
const scaleFactor = Math.sqrt(2) / 2;

export class RiskQualityComponents {
  readonly riskAppetite: number;
  readonly value: number;

  constructor(
    private readonly riskQuality: number,
    private readonly differentialValue: number
  ) {
    this.riskAppetite =
      scaleFactor *
        (Math.cos(rotationAngle) * riskQuality -
          Math.sin(rotationAngle) * differentialValue) +
      xTranslation;
    this.value =
      scaleFactor *
        (Math.sin(rotationAngle) * riskQuality +
          Math.cos(rotationAngle) * differentialValue) +
      yTranslation;
  }

  static of(riskQuality: number, differentialValue: number) {
    return new RiskQualityComponents(riskQuality, differentialValue);
  }
}
