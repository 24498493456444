import p5Types from 'p5';

export default class Axes {
  padding: number;
  guidesGap: number;
  maxGuides = { HORIZONTAL: 4, VERTICAL: 4 };

  constructor(private readonly canvasOptions: any) {
    this.guidesGap = canvasOptions.GUIDES_GAP;
    this.padding = canvasOptions.AXES_PADDING;
  }

  drawZones(p5: p5Types) {
    // Yellow zone
    p5.noStroke();
    p5.fill('#f7f1b5');
    p5.rect(
      this.padding,
      this.canvasOptions.HEIGHT - this.padding,
      this.guidesGap * this.maxGuides.VERTICAL,
      -this.guidesGap * this.maxGuides.HORIZONTAL
    );

    // Red zone
    p5.noStroke();
    p5.fill('#f0d8dc');
    p5.triangle(
      this.padding + this.guidesGap * (this.maxGuides.VERTICAL - 1),
      this.canvasOptions.HEIGHT - this.padding,

      this.padding,
      this.canvasOptions.HEIGHT - this.padding,

      this.padding,
      this.canvasOptions.HEIGHT -
        this.padding -
        this.guidesGap * (this.maxGuides.HORIZONTAL - 1)
    );

    // Green zone
    p5.noStroke();
    p5.fill('#cef2ce');
    p5.triangle(
      this.padding + this.guidesGap * this.maxGuides.VERTICAL,
      this.canvasOptions.HEIGHT -
        this.padding -
        this.guidesGap * this.maxGuides.HORIZONTAL,

      this.padding + this.guidesGap,
      this.canvasOptions.HEIGHT -
        this.padding -
        this.guidesGap * this.maxGuides.HORIZONTAL,

      this.padding + this.guidesGap * this.maxGuides.VERTICAL,
      this.canvasOptions.HEIGHT - this.padding - this.guidesGap
    );
  }

  drawGuides(p5: p5Types) {
    p5.stroke(0, 0, 0, 15);
    p5.strokeWeight(1);

    // Draw horizontal guides
    Array.from({ length: this.maxGuides.HORIZONTAL - 1 }, (_, i) => {
      p5.line(
        this.padding,
        this.canvasOptions.HEIGHT - this.padding - (i + 1) * this.guidesGap,
        this.canvasOptions.WIDTH - this.padding,
        this.canvasOptions.HEIGHT - this.padding - (i + 1) * this.guidesGap
      );
    });

    // Draw vertical guides
    Array.from({ length: this.maxGuides.VERTICAL - 1 }, (_, i) => {
      p5.line(
        this.padding + (i + 1) * this.guidesGap,
        this.canvasOptions.HEIGHT - this.padding,
        this.padding + (i + 1) * this.guidesGap,
        this.padding
      );
    });
  }

  draw(p5: p5Types) {
    this.drawZones(p5);
    this.drawGuides(p5);

    // Draw gray box
    p5.stroke(40);
    p5.fill(150);

    p5.strokeWeight(2);

    // Horizontal
    p5.line(
      this.padding,
      this.canvasOptions.HEIGHT - this.padding,
      this.canvasOptions.WIDTH - this.padding,
      this.canvasOptions.HEIGHT - this.padding
    );

    // Vertical
    p5.line(
      this.padding,
      this.canvasOptions.HEIGHT - this.padding,
      this.padding,
      this.padding
    );

    p5.noStroke();
    p5.fill('Black');

    // Top-facing arrow
    p5.triangle(
      this.padding - 4,
      this.padding,
      this.padding + 4,
      this.padding,
      this.padding,
      this.padding - 10
    );

    // Right-facing arrow
    p5.triangle(
      this.canvasOptions.WIDTH - this.padding - 10 + 8,
      this.canvasOptions.HEIGHT - this.padding - 4,
      this.canvasOptions.WIDTH - this.padding - 10 + 8,
      this.canvasOptions.HEIGHT - this.padding + 4,
      this.canvasOptions.WIDTH - this.padding + 8,
      this.canvasOptions.HEIGHT - this.padding
    );

    p5.noStroke();
    p5.fill('#808080');
    p5.textSize(16);
    p5.textStyle(p5.NORMAL);

    // Left
    p5.text('Value', this.padding - 48, this.canvasOptions.HEIGHT / 2);

    // Down
    p5.text(
      'Appetite',
      this.canvasOptions.WIDTH / 2,
      this.canvasOptions.HEIGHT - this.padding + 36
    );
  }
}
