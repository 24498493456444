import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";

export default function useDiagnostics() {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      datadogRum.init({
        applicationId: process.env.DATADOG_APPLICATION_ID,
        clientToken: process.env.DATADOG_CLIENT_TOKEN,
        site: "datadoghq.com",
        service: process.env.DATADOG_SERVICE_NAME,
        env: process.env.ENV,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: "mask-user-input",
      });

      datadogRum.startSessionReplayRecording();
    }
  }, [datadogRum]);
}
