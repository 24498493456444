import { GameRiskQuality, solutions } from "../data/incidents";

export class TeamMemberConfiguration {
  readonly gameRiskQuality: GameRiskQuality;
  readonly memberConfiguration: string;

  constructor(
    private readonly solutionA: string,
    private readonly solutionB?: string
  ) {
    console.log("»» ", solutionA, solutionB);

    this.gameRiskQuality = this.calculateGameRiskQualityFromSolutions(
      solutionA,
      solutionB
    );
    this.memberConfiguration = this.getConfigurationFrom(
      solutionB || solutionA
    );
  }

  private calculateGameRiskQualityFromSolutions(
    solutionA: string,
    solutionB?: string
  ) {
    const isSolutionAGood =
      solutions.find((solution) => solution.id === solutionA)!.riskQuality > 0;

    const _solutionB = solutions.find((solution) => solution.id === solutionB);

    if (!_solutionB)
      return isSolutionAGood ? GameRiskQuality.GOOD : GameRiskQuality.BAD;

    const [A, B] = solutionB.split(".");
    const finalSolution = `A${A[1]}_B${B}`;

    if (GOOD_SOLUTIONS.includes(finalSolution)) return GameRiskQuality.GOOD;
    if (BAD_SOLUTIONS.includes(finalSolution)) return GameRiskQuality.BAD;
    return GameRiskQuality.AVERAGE;

    // const isSolutionBGood = _solutionB.riskQuality > 0;

    // if (isSolutionAGood && isSolutionBGood) return GameRiskQuality.GOOD;
    // if (!isSolutionAGood && !isSolutionBGood) return GameRiskQuality.BAD;
    // return GameRiskQuality.AVERAGE;
  }

  private getConfigurationFrom(solution: string) {
    // Given the solutionB choice option, there's only one possible solutionA applied,
    // so that solutionB id is enough to locate team member configuration among the ones
    // given by gameRiskQuality. Could be solution A if Game is Out of Time
    return solution;
  }

  static from(solutionA: string, solutionB?: string) {
    return new TeamMemberConfiguration(solutionA, solutionB);
  }
}

const GOOD_SOLUTIONS = ["A1_B3", "A1_B4", "A2_B3", "A2_B4"];
const BAD_SOLUTIONS = ["A3_B1", "A3_B2", "A4_B2", "A4_B3"];
