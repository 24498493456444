export enum GameRiskQuality {
  BAD = "bad",
  AVERAGE = "average",
  GOOD = "good",
}

enum Values {
  WORST = -2,
  BAD = -1,
  NEUTRAL = 0,
  GOOD = 1,
  BEST = 2,
}

interface ISolution {
  id: string;
  incidentId: string;
  riskQuality: number;
  differentialValue?: number;
  gameRiskQuality: GameRiskQuality;
}

export const solutions: ISolution[] = [
  // INCIDENT A
  {
    id: "A1.1",
    incidentId: "A1",
    differentialValue: Values.BEST,
    riskQuality: Values.BEST,
    gameRiskQuality: GameRiskQuality.GOOD,
  },
  {
    id: "A1.2",
    incidentId: "A1",
    differentialValue: Values.BAD,
    riskQuality: Values.GOOD,
    gameRiskQuality: GameRiskQuality.GOOD,
  },
  {
    id: "A1.3",
    incidentId: "A1",
    differentialValue: Values.GOOD,
    riskQuality: Values.BAD,
    gameRiskQuality: GameRiskQuality.BAD,
  },
  {
    id: "A1.4",
    incidentId: "A1",
    differentialValue: Values.WORST,
    riskQuality: Values.WORST,
    gameRiskQuality: GameRiskQuality.BAD,
  },

  // INCIDENT B
  {
    id: "B1.1",
    incidentId: "B1",
    differentialValue: 2,
    riskQuality: 0,
    gameRiskQuality: GameRiskQuality.AVERAGE,
  },
  {
    id: "B1.2",
    incidentId: "B1",
    differentialValue: 2,
    riskQuality: 1,
    gameRiskQuality: GameRiskQuality.AVERAGE,
  },
  {
    id: "B1.3",
    incidentId: "B1",
    differentialValue: 1,
    riskQuality: 3,
    gameRiskQuality: GameRiskQuality.GOOD,
  },
  {
    id: "B1.4",
    incidentId: "B1",
    differentialValue: 0,
    riskQuality: 4,
    gameRiskQuality: GameRiskQuality.GOOD,
  },

  {
    id: "B2.1",
    incidentId: "B2",
    differentialValue: -1,
    riskQuality: -1,
    gameRiskQuality: GameRiskQuality.AVERAGE,
  },
  {
    id: "B2.2",
    incidentId: "B2",
    differentialValue: -1,
    riskQuality: 0,
    gameRiskQuality: GameRiskQuality.AVERAGE,
  },
  {
    id: "B2.3",
    incidentId: "B2",
    differentialValue: -1,
    riskQuality: 2,
    gameRiskQuality: GameRiskQuality.GOOD,
  },
  {
    id: "B2.4",
    incidentId: "B2",
    differentialValue: -1,
    riskQuality: 3,
    gameRiskQuality: GameRiskQuality.GOOD,
  },

  {
    id: "B3.1",
    incidentId: "B3",
    differentialValue: 1,
    riskQuality: -3,
    gameRiskQuality: GameRiskQuality.BAD,
  },
  {
    id: "B3.2",
    incidentId: "B3",
    differentialValue: 1,
    riskQuality: -2,
    gameRiskQuality: GameRiskQuality.BAD,
  },
  {
    id: "B3.3",
    incidentId: "B3",
    differentialValue: 1,
    riskQuality: 1,
    gameRiskQuality: GameRiskQuality.AVERAGE,
  },
  {
    id: "B3.4",
    incidentId: "B3",
    differentialValue: 1,
    riskQuality: 0,
    gameRiskQuality: GameRiskQuality.AVERAGE,
  },

  {
    id: "B4.1",
    incidentId: "B4",
    differentialValue: -2,
    riskQuality: -1,
    gameRiskQuality: GameRiskQuality.AVERAGE,
  },
  {
    id: "B4.2",
    incidentId: "B4",
    differentialValue: -1,
    riskQuality: -3,
    gameRiskQuality: GameRiskQuality.BAD,
  },
  {
    id: "B4.3",
    incidentId: "B4",
    differentialValue: 0,
    riskQuality: -4,
    gameRiskQuality: GameRiskQuality.BAD,
  },
  {
    id: "B4.4",
    incidentId: "B4",
    differentialValue: -2,
    riskQuality: 0,
    gameRiskQuality: GameRiskQuality.AVERAGE,
  },
];

// This are the riskQuality values based on both solution A and B
export const finalRiskQualityComponents = new Map([
  // GOOD
  ["B1.3", [Values.BEST, Values.GOOD]],
  ["B1.4", [Values.BEST, Values.BEST]],
  ["B2.3", [Values.GOOD, Values.GOOD]],
  ["B2.4", [Values.GOOD, Values.BEST]],

  // BAD
  ["B3.1", [Values.BAD, Values.WORST]],
  ["B3.2", [Values.BAD, Values.BAD]],
  ["B4.2", [Values.WORST, Values.BAD]],
  ["B4.3", [Values.WORST, Values.WORST]],

  // AVERAGE

  ["B1.1", [Values.BEST, Values.WORST]],
  ["B1.2", [Values.BEST, Values.BAD]],
  ["B2.1", [Values.GOOD, Values.WORST]],
  ["B2.2", [Values.GOOD, Values.BAD]],
  ["B3.3", [Values.BAD, Values.BEST]],
  ["B3.4", [Values.BAD, Values.GOOD]],
  ["B4.1", [Values.WORST, Values.GOOD]],
  ["B4.4", [Values.WORST, Values.BEST]],
]);
