/* eslint-disable max-params */
export default class HttpClient {
  public static async request(
    method: 'GET' | 'POST' | 'DELETE',
    endpoint: string,
    body?: object,
    customConfig?: RequestInit
  ) {
    return fetch(`${process.env.API_ENDPOINT}/${endpoint}`, {
      method,
      ...customConfig,
      headers: {
        ...customConfig?.headers,
      },
      body: body ? JSON.stringify(body) : undefined,
    }).then(async response => {
      if (response.status === 403) {
        const errorMessage = await response.text();
        console.error('HTTP 403 error: ', errorMessage);
        return Promise.reject(new Error(errorMessage));
      } else if (response.status === 401) {
        window.location.assign(window.location.toString());
        return;
      } else if (response.ok) {
        const responseText = await response.text();

        try {
          const responseJson = JSON.parse(responseText);
          return responseJson;
        } catch (jsonParsingError) {
          return responseText;
        }
      } else return Promise.reject(new Error(await response.text()));
    });
  }

  public static async get(endpoint: string, config: object = {}) {
    return await this.request('GET', endpoint, undefined, config);
  }

  public static async post(endpoint: string, body: object, config: any = {}) {
    return await this.request('POST', endpoint, body, {
      ...config,
      headers: {
        'Content-Type': 'application/json',
        ...config.headers,
      },
    });
  }

  public static async delete(
    endpoint: string,
    body: object = {},
    config: any = {}
  ) {
    return await this.request('DELETE', endpoint, body, {
      ...config,
      headers: {
        'Content-Type': 'application/json',
        ...config.headers,
      },
    });
  }
}
