/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import Sketch from 'react-p5';
import p5Types from 'p5'; // Import this for typechecking and intellisense
import Select from 'react-select';
import { IGraphicsClassDebrief } from './class-debrief';
import Axes from './matrix-plot/axes';
import Circle from './matrix-plot/circle';

const UntypedSketch = Sketch as any;

interface IProps {
  data: IGraphicsClassDebrief[];
  showSelect?: boolean;
  highlightedTeamId?: string;
}

const CanvasOptions = {
  WIDTH: 982,
  HEIGHT: 982,
  COLOR: 235,
  AXES_PADDING: 72,
  GUIDES_GAP: 200,
  MARKER_SIZE: 42,
};

export const MatrixPlot = (props: IProps) => {
  const [axes] = useState(new Axes(CanvasOptions));
  const [selectedTeams, setSelectedTeams] = useState<
    { label: string; value: string }[]
  >([]);
  const [timerImage, setTimerImage] = useState<p5Types.Image>();
  const [markers, setMarkers] = useState<Circle[]>([]);

  const [isSketchMounted, setSketchMounted] = useState<boolean>(false);

  const setup = (p5: p5Types, parentRef: Element) => {
    if (isSketchMounted || !parentRef) return;

    p5.createCanvas(CanvasOptions.WIDTH, CanvasOptions.HEIGHT).parent(
      parentRef
    );

    setSketchMounted(true);
  };

  const toChartCoords = (appetite: number, value: number) => ({
    x: CanvasOptions.AXES_PADDING + appetite * CanvasOptions.GUIDES_GAP,
    y:
      CanvasOptions.HEIGHT -
      CanvasOptions.AXES_PADDING -
      value * CanvasOptions.GUIDES_GAP,
  });

  const draw = (p5: p5Types) => {
    if (!isSketchMounted) return;

    p5.background('white');
    axes.draw(p5);

    markers
      .filter(
        (marker, markerIndex) =>
          markers.findIndex(({ x, y }) => x === marker.x && y === marker.y) ===
          markerIndex
      )
      .map(marker => ({
        ...marker,
        label: markers
          .filter(({ x, y }) => x === marker.x && y === marker.y)
          .map(({ label }) => label)
          .join(',\n'),
        draw: marker.draw,
      }))
      .forEach(({ draw }) => draw(p5, timerImage));
  };

  const preload = (p5: p5Types) => {
    p5.loadImage(
      'https://cdn-icons-png.flaticon.com/128/850/850960.png',
      img => {
        setTimerImage(img);
      }
    );
  };

  useEffect(() => {
    setMarkers(
      props.data
        .filter(d =>
          selectedTeams?.some(st => st.value === 'all') || !props.showSelect
            ? true
            : selectedTeams.some(st => st.value === d.teamId)
        )
        .map(st => {
          const teamInfo = props.data.find(d => d.teamId === st.teamId);

          if (teamInfo) {
            const coords = toChartCoords(teamInfo.appetite, teamInfo.value);

            return new Circle(
              coords.x,
              coords.y,
              teamInfo.teamId,
              teamInfo.mode === 1,
              teamInfo.teamId === `${props.highlightedTeamId}`
            );
          }
        })
        .filter(Boolean) as Circle[]
    );
  }, [selectedTeams]);

  const selectOptions = selectedTeams.some(st => st.value === 'all')
    ? []
    : [
        {
          label: 'All teams',
          value: 'all',
        },
      ].concat(
        props.data.map(d => ({
          label: `Team ${d.teamId}`,
          value: d.teamId,
        }))
      );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div id="sketch-container" />
      <UntypedSketch setup={setup} draw={draw} preload={preload} />

      <div
        style={{
          display: 'flex',
          width: '100%',
        }}
      >
        {props.showSelect && (
          <div style={{ width: '240px', margin: '64px auto auto auto' }}>
            <p style={{ fontWeight: 500, color: '#505070' }}>Filter teams:</p>
            <Select
              isMulti
              name="colors"
              options={selectOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Filter teams..."
              onChange={changes =>
                setSelectedTeams(changes as { label: string; value: string }[])
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};
