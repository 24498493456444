import { CanvasUtils } from '../canvas-utils';
import {
  IConfiguration,
  IGraphicsIndividualDebrief,
} from './individual-debrief';
import React, { useEffect } from 'react';

interface ICanvasItem {
  index: string;
  configuration: IConfiguration;
  selectedMemberDebrief: IGraphicsIndividualDebrief;
}

const canvasWidth = 500;
const canvasHeight = 150;
export const xStart = 100;
export const xEnd = 400;
export const yFirstLine = 50;
export const ySecondLine = 100;
export const arrowAngle = 5;

export const ConfigurationPlot = ({
  index,
  configuration,
  selectedMemberDebrief,
}: ICanvasItem) => {
  const drawCanvas = () => {
    const canvas: any = document.querySelector(`#canvas_${index}`);
    if (canvas && canvas.getContext) {
      const ctx = canvas.getContext('2d');

      // Highlight background of selected member's configuration
      if (selectedMemberDebrief.memberConfiguration === configuration.id) {
        ctx.fillStyle = '#d9ff074d';
        ctx.fillRect(50, 10, 400, 130);
      }

      const xArrowStart = xStart + arrowAngle;
      const xArrowEnd = xEnd - arrowAngle;

      const yArrowMax1 = yFirstLine + arrowAngle;
      const yArrowMin1 = yFirstLine - arrowAngle;
      const yArrowMax2 = ySecondLine + arrowAngle;
      const yArrowMin2 = ySecondLine - arrowAngle;

      // Draw first line
      CanvasUtils.drawLine(ctx, {
        x: { from: xStart, to: xArrowStart },
        y: { from: yFirstLine, to: yArrowMin1 },
      });
      CanvasUtils.drawLine(ctx, {
        x: { from: xStart, to: xEnd },
        y: { from: yFirstLine, to: yFirstLine },
      });
      CanvasUtils.drawLine(ctx, {
        x: { from: xStart, to: xArrowStart },
        y: { from: yFirstLine, to: yArrowMax1 },
      });
      CanvasUtils.drawLine(ctx, {
        x: { from: xEnd, to: xArrowEnd },
        y: { from: yFirstLine, to: yArrowMax1 },
      });
      CanvasUtils.drawLine(ctx, {
        x: { from: xEnd, to: xArrowEnd },
        y: { from: yFirstLine, to: yArrowMin1 },
      });

      // Draw second line
      CanvasUtils.drawLine(ctx, {
        x: { from: xStart, to: xArrowStart },
        y: { from: ySecondLine, to: yArrowMin2 },
      });
      CanvasUtils.drawLine(ctx, {
        x: { from: xStart, to: xEnd },
        y: { from: ySecondLine, to: ySecondLine },
      });
      CanvasUtils.drawLine(ctx, {
        x: { from: xStart, to: xArrowStart },
        y: { from: ySecondLine, to: yArrowMax2 },
      });
      CanvasUtils.drawLine(ctx, {
        x: { from: xEnd, to: xArrowEnd },
        y: { from: ySecondLine, to: yArrowMax2 },
      });
      CanvasUtils.drawLine(ctx, {
        x: { from: xEnd, to: xArrowEnd },
        y: { from: ySecondLine, to: yArrowMin2 },
      });

      // Draw first response outcome
      CanvasUtils.drawOutcome(ctx, 'A', configuration.score[0]);

      // Draw second response outcome
      if (selectedMemberDebrief.timeOver) CanvasUtils.drawTimeOverSymbol(ctx);
      else CanvasUtils.drawOutcome(ctx, 'B', configuration.score[1]);
    }
  };

  useEffect(() => {
    drawCanvas();
  }, []);

  return (
    <div>
      <canvas width={canvasWidth} height={canvasHeight} id={`canvas_${index}`}>
        The browser doesn&apos;t support the canvas element
      </canvas>
    </div>
  );
};
