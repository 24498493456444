/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { getClassDebrief } from '../services/api-service';
import { UglyRightPanel, Wrapper } from '../ui-components';
import { MatrixPlot } from './matrix-plot';
import { GraphicsTransformation } from '../services/graphics-transformation';
import DimensionsDescription from '../components/DimensionsDescriptions';
import { IResultFetchFn } from '../models';

export interface IGraphicsClassDebrief {
  teamId: string;
  appetite: number;
  value: number;
  mode: number;
  timeOver: boolean;
}

export const ClassDebrief: React.FC<{ fetch: IResultFetchFn }> = props => {
  const [transformedClassDebrief, setTransformedClassDebrief] = useState<
    IGraphicsClassDebrief[]
  >([]);

  const inTimeSet = transformedClassDebrief.filter(
    (gcd: IGraphicsClassDebrief) => !gcd.timeOver
  );
  const outTimeSet = transformedClassDebrief.filter(
    (gcd: IGraphicsClassDebrief) => gcd.timeOver
  );

  useEffect(() => {
    getClassDebrief(props.fetch)().then(classDebrief => {
      setTransformedClassDebrief(
        GraphicsTransformation.transformClassDebrief(classDebrief)
      );
    });
  }, []);

  const ClassDebriefMainView = () => (
    <>
      <MatrixPlot data={inTimeSet} showSelect />
      <UglyRightPanel>
        <p
          style={{
            fontWeight: 'bold',
            fontSize: '2.5em',
            color: '#404040',
          }}
        >
          Decision Paralysis Zone
        </p>
        <br />
        {outTimeSet.map((item: IGraphicsClassDebrief, key: number) => (
          <span
            key={key}
            style={{
              borderRadius: '32px',
              backgroundColor: 'Indigo',
              padding: '4px 12px',
              color: 'White',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Team {item.teamId}
          </span>
        ))}
      </UglyRightPanel>
    </>
  );

  return (
    <>
      <style>
        {`
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
`}
      </style>
      <div
        style={{
          padding: '24px 64px',
        }}
      >
        <h2
          style={{
            fontWeight: 800,
            fontSize: '3.5em',
            color: '#222244',
            fontFamily: 'Inter',
            marginBottom: '64px',
          }}
        >
          Quality of risk
          <span style={{ opacity: 0.2, margin: 'auto 16px' }}>|</span>
          Class overview
        </h2>

        <DimensionsDescription />

        <Wrapper>
          {transformedClassDebrief ? <ClassDebriefMainView /> : 'Loading...'}
        </Wrapper>
      </div>
    </>
  );
};
