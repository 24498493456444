import React, { useEffect, useState } from "react";
import {
  GameRiskQuality,
  finalRiskQualityComponents,
  solutions,
} from "../data/incidents";
import { getClassDebrief, getDebriefForTeam } from "../services/api-service";
import { TeamMemberConfiguration } from "../services/team-member-configuration";
import { GlobalResult, Wrapper } from "../ui-components";
import { ConfigurationPlot } from "./configuration-plot";
import { MatrixPlot } from "../class/matrix-plot";
import { GraphicsTransformation } from "../services/graphics-transformation";
import { IGraphicsClassDebrief } from "../class/class-debrief";
import DimensionsDescription from "../components/DimensionsDescriptions";
import { IResultFetchFn } from "../models";

export interface IGraphicsIndividualDebrief {
  memberId: string;
  gameRiskQuality: GameRiskQuality;
  memberConfiguration: string;
  timeOver: boolean;
}

export interface IConfiguration {
  id: string;
  score: number[];
}

export const IndividualDebrief: React.FC<{
  fetch: IResultFetchFn;
  interpretationId: string;
}> = (props) => {
  const [transformedIndividualDebrief, setTransformedIndividualDebrief] =
    useState<IGraphicsIndividualDebrief[]>([] as IGraphicsIndividualDebrief[]);
  const [transformedTeamDebrief, setTransformedTeamDebrief] = useState<any>();
  const [selectedMemberId, setSelectedMemberId] = useState("");
  const [selectedMemberDebrief, setSelectedMemberDebrief] =
    useState<IGraphicsIndividualDebrief>({} as IGraphicsIndividualDebrief);
  const [configurations, setConfigurations] = useState<IConfiguration[]>([]);
  const [teamConfigurations, setTeamConfigurations] = useState<
    IConfiguration[]
  >([]);
  const [transformedClassDebrief, setTransformedClassDebrief] = useState<
    IGraphicsClassDebrief[]
  >([]);

  const changeCurrentMemberViewTo = (memberId: string) => {
    setSelectedMemberId(memberId);
  };

  const getConfigurationsWithQuality = (
    gameRiskQuality: string
  ): IConfiguration[] =>
    solutions
      .filter((solution) => solution.gameRiskQuality === gameRiskQuality)
      .filter((solution) => solution.incidentId !== "A1")
      .map(({ id }: { id: string }) => ({
        id,
        score: finalRiskQualityComponents.get(id),
      }));

  const getSelectedMemberDebriefAndConfigurations = () => {
    const selectedMemberDebrief = transformedIndividualDebrief.find(
      (deb: IGraphicsIndividualDebrief) => deb.memberId === selectedMemberId
    );

    if (selectedMemberDebrief) {
      setSelectedMemberDebrief(selectedMemberDebrief);

      setConfigurations([
        ...getConfigurationsWithQuality(selectedMemberDebrief.gameRiskQuality),
      ]);

      setTeamConfigurations([
        ...getConfigurationsWithQuality(transformedTeamDebrief.gameRiskQuality),
      ]);
    }
  };

  const transformTeamDebrief = (teamDebrief: any) => {
    const { gameRiskQuality, memberConfiguration } =
      TeamMemberConfiguration.from(
        teamDebrief.solutionA,
        teamDebrief.solutionB
      );

    setTransformedTeamDebrief({
      teamId: teamDebrief.teamId,
      gameRiskQuality,
      memberConfiguration,
      timeOver: teamDebrief.timeOver,
    });
  };

  useEffect(() => {
    getSelectedMemberDebriefAndConfigurations();
  }, [selectedMemberId]);

  useEffect(() => {
    getDebriefForTeam(props.fetch)(props.interpretationId).then(
      (individualDebrief) => {
        setTransformedIndividualDebrief(
          GraphicsTransformation.transformIndividualDebrief(
            individualDebrief.members
          )
        );

        transformTeamDebrief(individualDebrief);

        changeCurrentMemberViewTo(props.interpretationId);
      }
    );

    getClassDebrief(props.fetch)().then((classDebrief) => {
      setTransformedClassDebrief(
        GraphicsTransformation.transformClassDebrief(classDebrief)
      );
    });
  }, []);

  const inTimeSet = transformedClassDebrief.filter(
    (gcd: IGraphicsClassDebrief) => !gcd.timeOver
  );

  console.log("team-confs:", teamConfigurations);
  console.log("team-debri", transformedTeamDebrief);

  const IndividualDebriefMainView = () => (
    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {transformedTeamDebrief && (
          <MatrixPlot
            showSelect={false}
            data={inTimeSet}
            highlightedTeamId={transformedTeamDebrief?.teamId}
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "100px",
        }}
      >
        {selectedMemberDebrief && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              {1 === 1 ? "You are a " : `Player ${selectedMemberId}`}
            </p>

            <GlobalResult riskQuality={selectedMemberDebrief.gameRiskQuality} />

            <p style={{ color: "Gray" }}>Decisions quality drilldown</p>

            {configurations?.map(
              (configuration: IConfiguration, index: number) => (
                <ConfigurationPlot
                  key={configuration.id}
                  index={`${index}`}
                  configuration={configuration}
                  selectedMemberDebrief={selectedMemberDebrief}
                />
              )
            )}
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Your team is a
          </p>

          <GlobalResult riskQuality={transformedTeamDebrief?.gameRiskQuality} />

          <p style={{ color: "Gray" }}>Decisions quality drilldown</p>

          {teamConfigurations?.map(
            (configuration: IConfiguration, index: number) => (
              <ConfigurationPlot
                key={configuration.id}
                index={`team-${index}`}
                configuration={configuration}
                selectedMemberDebrief={transformedTeamDebrief}
              />
            )
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <style>
        {`
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
`}
      </style>
      <div
        style={{
          padding: "24px 64px",
        }}
      >
        <h2
          style={{
            fontWeight: 800,
            fontSize: "3.5em",
            color: "#222244",
            fontFamily: "Inter",
            marginBottom: "64px",
          }}
        >
          Quality of risk
          <span style={{ opacity: 0.2, margin: "auto 16px" }}>|</span>
          Team and student focus
        </h2>

        <DimensionsDescription />

        <Wrapper>
          {transformedIndividualDebrief ? (
            <IndividualDebriefMainView />
          ) : (
            "Loading..."
          )}
        </Wrapper>
      </div>
    </>
  );
};
