import { useRef, useEffect } from "react";
import qs from "query-string";
import Header from "./components/Header";
import useDiagnostics from "./hooks/useDiagnostics.hook";
import { ClassDebrief, IndividualDebrief } from "./fireballs-debrief";
import buildAssetPath from "./utils/buildAssetPath";
import HttpClient from "./services/HttpClient.service";

export const App = () => {
  const ref = useRef<HTMLDivElement>();
  useDiagnostics();

  const parsed = qs.parse(location.search);
  const debriefingId: string = parsed?.d?.toString();
  const interpretationId: string = parsed?.i?.toString();

  if (!debriefingId) return <h1>Please specify ?d=[debriefingId]</h1>;

  const fetch = () =>
    HttpClient.get(
      `${process.env.API_ENDPOINT}/game-results/payloads?gamingSessionIds[]=${debriefingId}`
    );

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header brandImg={buildAssetPath("/images/logo_full.png")} />
      {interpretationId ? (
        <IndividualDebrief fetch={fetch} interpretationId={interpretationId} />
      ) : (
        <>
          <ClassDebrief fetch={fetch} />
          {
            <div
              style={{
                backgroundColor: "#00000016",
                borderRadius: "16px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "32px",
                marginLeft: "64px",
                marginRight: "64px",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "2.5em",
                  color: "#404040",
                }}
              >
                Students Report Links
              </p>
              <ul
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  flexWrap: "wrap",
                  gap: "1rem",
                }}
              >
                {gamingSession.teams.map((team) => (
                  <li
                    key={team.id}
                    style={{
                      padding: "0.5rem",
                      borderRadius: "0.5rem",
                      border: "2px solid Silver",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1.5rem",
                        lineHeight: "2rem",
                        fontWeight: "bold",
                        marginBottom: "0.5rem",
                      }}
                    >
                      Team {team.name.toUpperCase()}
                    </p>
                    <ul>
                      {gamingSession.candidatePlayers
                        .filter((player) => player.team === team.id)
                        .map((player) => (
                          <li
                            key={player.id}
                            style={{
                              fontSize: "1.25rem",
                              lineHeight: "1.75rem",
                              textDecoration:
                                gamingSession.playersParties.find(
                                  (party) => party.id === player.partyId
                                ).spokesperson === player.id
                                  ? "underline"
                                  : "none",
                            }}
                          >
                            <a
                              href={`/?d${debriefingId}&s=${player.id}`}
                              target="_blank"
                            >
                              {player.traits.displayName}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          }
        </>
      )}
    </div>
  );
};

// TODO replace with the real data
const gamingSession = {
  teams: [
    {
      id: "t1",
      name: "t1",
    },
  ],
  candidatePlayers: [
    {
      id: "p1",
      team: "t1",
      partyId: "pp1",
      traits: {
        displayName: "p1",
      },
    },
  ],
  playersParties: [
    {
      id: "pp1",
      spokesperson: "p1",
    },
  ],
};
