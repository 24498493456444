/* eslint-disable indent */
import { solutions } from "../data/incidents";
import { FireballsGameResultPayload, IResultFetchFn } from "../models";

export interface IClassDebriefResult {
  teamId: string;
  riskQuality: number;
  differentialValue: number;
  mode: number;
  timeOver: boolean;
}

export const getClassDebrief = (fetch: IResultFetchFn) => async () => {
  const res = await fetch();
  return extractClassDebrief(res);
};

export function extractClassDebrief(
  payloads: FireballsGameResultPayload[]
): IClassDebriefResult[] {
  return payloads.map(observationsToClassDebriefResult);
}

function observationsToClassDebriefResult(
  payload: FireballsGameResultPayload
): IClassDebriefResult {
  const team = payload.observations.find((o) => o.type === "team");
  const solutionA = solutions.find((s) => s.id === `A1.${team.solutionA}`);
  const solutionB = solutions.find(
    (s) => s.id === `B${team.solutionA}.${team.solutionB}`
  );

  return {
    teamId: team.object,
    riskQuality: solutionB ? solutionB.riskQuality : solutionA.riskQuality,
    differentialValue: solutionB
      ? solutionB.differentialValue
      : solutionA.differentialValue,
    mode: team.mode === "on" ? 1 : 0,
    timeOver: team.isTimeOver,
  };
}

const observationsToIndividualDebriefResult = (
  payload: FireballsGameResultPayload
): IIndividualDebriefResult => {
  const team = payload.observations.find((o) => o.type === "team");

  const solutionA = solutions.find((s) => s.id === `A1.${team.solutionA}`);
  const solutionB = solutions.find(
    (s) => s.id === `B${team.solutionA}.${team.solutionB}`
  );

  return {
    teamId: team.object,
    solutionA: solutionA.id,
    solutionB: solutionB ? solutionB.id : null,
    timeOver: team.isTimeOver,
    members: payload.observations
      .filter((o) => o.type === "player")
      .map((p) => {
        const solutionA = solutions.find((s) => s.id === `A1.${p.solutionA}`);
        const solutionB = solutions.find(
          (s) => s.id === `B${p.solutionA}.${p.solutionB}`
        );

        return {
          memberId: p.object,
          solutionA: solutionA.id,
          solutionB: solutionB ? solutionB.id : null,
          timeOver: p.isTimeOver,
        };
      }),
  };
};

export interface IIndividualDebriefResult {
  teamId: string;
  solutionA: string;
  solutionB: string;
  timeOver: boolean;
  members: IMember[];
}

export interface IMember {
  memberId: string;
  solutionA: string;
  solutionB?: string;
  timeOver: boolean;
}

export const getDebriefForTeam =
  (fetch: IResultFetchFn) => async (interpretationId: string) => {
    const res = await fetch();

    const teamId: string = res
      .filter(Boolean)
      .find((payloadEntry) =>
        payloadEntry.observations.some(
          (observation) =>
            observation.type === "player" &&
            observation.object === interpretationId
        )
      )
      ?.observations.find((observation) => observation.type === "team").object;

    const payload = res
      .filter(Boolean)
      .find((payloadEntry) =>
        payloadEntry.observations.some(
          (observation) =>
            observation.type === "team" && observation.object === teamId
        )
      );

    return payload
      ? observationsToIndividualDebriefResult({ teamId, ...payload })
      : {
          teamId: "",
          solutionA: "",
          solutionB: "",
          timeOver: false,
          members: [],
        };
  };

export enum GameRiskQuality {
  BAD = "bad",
  AVERAGE = "average",
  GOOD = "good",
}
