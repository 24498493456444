import React from 'react';

const DimensionsDescription = () => (
  <>
    <h4
      style={{
        fontWeight: 800,
        fontSize: '1.9em',
        color: '#222244',
        fontFamily: 'Inter',
      }}
    >
      Dimensions
    </h4>
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(200px, 1fr))',
        gridTemplateRows: 'repeat(2, minmax(200px, 1fr))',
        gridGap: '64px',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          borderLeft: '4px solid Gainsboro',
          paddingLeft: '20px',
        }}
      >
        <h5
          style={{
            fontSize: '1.2em',
            fontWeight: 'bold',
            color: '#222250',
          }}
        >
          Risk appetite
        </h5>
        <p>
          Risk appetite is the level of risk that an organization is prepared to
          accept in pursuit of its objectives, before action is deemed necessary
          to reduce the risk. It represents a balance between the potential
          benefits of innovation and the threats, that change inevitably brings.
          <br />
          <br />
          It is measured on a scale from 1 to 4, where 1 = risk adverse and 4 =
          risk hungry.
        </p>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          borderLeft: '4px solid Gainsboro',
          paddingLeft: '20px',
        }}
      >
        <h5
          style={{
            fontSize: '1.2em',
            fontWeight: 'bold',
            color: '#222250',
          }}
        >
          Created Value
        </h5>
        <p>
          Measure the intensity of the value created for the organization
          expressed on a scale from 1 to 4, where 1 = low value creation and 4 =
          high value creation.
        </p>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          borderLeft: '4px solid Gainsboro',
          paddingLeft: '20px',
        }}
      >
        <h5
          style={{
            fontSize: '1.2em',
            fontWeight: 'bold',
            color: '#222250',
          }}
        >
          Quality of risk zones
        </h5>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '32px',
            marginBottom: '4px',
          }}
        >
          <span
            style={{
              width: '48px',
              height: '48px',
              backgroundColor: '#CEF2CE',
              borderBottom: '2px solid rgb(186, 222, 200)',
              display: 'inline-block',
              position: 'relative',
              marginRight: '16px',
              borderRadius: '4px',
            }}
          />
          <p style={{ fontWeight: 'bold', color: 'rgb(85 85 101)' }}>
            Good risk taker
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '4px',
          }}
        >
          <span
            style={{
              width: '48px',
              height: '48px',
              backgroundColor: '#F7F1B5',
              borderBottom: '2px solid rgb(227, 221, 140)',
              display: 'inline-block',
              position: 'relative',
              marginRight: '16px',
              borderRadius: '4px',
            }}
          />
          <p style={{ fontWeight: 'bold', color: 'rgb(85 85 101)' }}>
            Random risk taker
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              width: '48px',
              height: '48px',
              backgroundColor: '#F0D8DC',
              borderBottom: '2px solid rgb(220, 190, 190)',
              display: 'inline-block',
              position: 'relative',
              marginRight: '16px',
              borderRadius: '4px',
            }}
          />
          <p style={{ fontWeight: 'bold', color: 'rgb(85 85 101)' }}>
            Bad risk taker
          </p>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          borderLeft: '4px solid Gainsboro',
          paddingLeft: '20px',
        }}
      >
        <h5
          style={{
            fontSize: '1.2em',
            fontWeight: 'bold',
            color: '#222250',
          }}
        >
          Time Factor
        </h5>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '32px',
          }}
        >
          <span
            style={{
              width: '38px',
              height: '38px',
              display: 'inline-block',
              position: 'relative',
              border: '2px solid Teal',
              borderRadius: '50%',
              marginRight: '16px',
            }}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/128/850/850960.png"
              style={{
                height: '18px',
                width: '18px',
                position: 'absolute',
                top: '-4px',
                right: '-8px',
              }}
            />
          </span>
          <p
            style={{
              fontWeight: 'bold',
              color: 'rgb(85 85 101)',
            }}
          >
            Team under Time Pressure
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <span
            style={{
              width: '38px',
              height: '38px',
              display: 'inline-block',
              position: 'relative',
              border: '2px solid Teal',
              borderRadius: '50%',
              marginRight: '16px',
            }}
          />
          <p
            style={{
              fontWeight: 'bold',
              color: 'rgb(85 85 101)',
            }}
          >
            Team without Time Pressure
          </p>
        </div>
      </div>
    </div>
  </>
);

export default DimensionsDescription;
